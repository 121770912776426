'use strict';
import { app } from '@/main.js'

const RolesService = {

    getPlantMenu(plant, country){
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/security/plantPermissions/${plant}/${country}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    }
}

export default RolesService;
